<div class="modal-header">
  <h5 class="modal-title mb-0" id="modal-basic-title">{{ header }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('closed')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="text-center">
    <h6 class="mb-0">{{ confirmMessage }}</h6>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('closed')">Cancel</button>
  <button type="button" class="btn btn-outline-danger" (click)="activeModal.close('confirmed')">Confirm</button>
</div>
