<section>
  <div class="d-flex">
    <div class="rightSideContainer">
      <app-header></app-header>
      <div class="dynamicAdminPageContainer">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
